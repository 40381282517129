<template>
  <BaseTableWrapper :table-init="tableInit">
    <h1 class="page-header">Companies</h1>
  </BaseTableWrapper>
</template>

<script>
import BaseTableWrapper from '@/components/BaseTableWrapper.vue'
import BaseActionsRequiresWrapper from '@/components/BaseActionsRequiresWrapper.vue'
import CompanyViewDetail from '@/components/CompanyViewDetail.vue'
import actionsWrapper from '@/utils/BaseActionsWrapper'
import { deepClone } from '@/utils/deepClone'
import { textLike } from '@/utils/predefined'
import PartnerTypeFilterVue from '@/components/PartnerTypeFilter.vue'
import OnboardingStatusTypeFilterVue from '@/components/OnboardingStatusTypeFilter.vue'
import companies from '@/services/companies'

const wrappedBaseActions = actionsWrapper(
  BaseActionsRequiresWrapper,
  'company',
  ['view', 'edit', 'delete', 'add-user'],
  'companies'
)

const columns = [
  {
    _t_id: '66cea342',
    prop: '/',
    text: 'Actions',
    component: wrappedBaseActions,
    sort: false,
    filter: false,
    detail: false,
    type: 'actions',
  },
  {
    _t_id: '66cea5d6',
    prop: 'name',
    text: 'Name',
    sort: true,
    filter: true,
    type: 'text',
    filterType: 'contains',
    sortProp: 'name',
    defaultSort: true,
    childMethod: 'and',
    predefined: textLike,
  },
  {
    _t_id: '66ceaa04',
    prop: ['address/street1', 'address/street2'],
    text: 'Address',
    sort: true,
    filter: true,
    type: 'text',
    filterType: 'contains',
    sortProp: 'address/street1',
  },
  {
    _t_id: '66ceab62',
    prop: ['address/city', 'address/state'],
    text: 'City and State',
    sort: true,
    filter: true,
    type: 'text',
    filterType: 'contains',
    sortProp: 'address/city',
  },
  {
    _t_id: '66cea738',
    prop: 'email',
    text: 'Email',
    sort: true,
    filter: true,
    type: 'text',
    filterType: 'contains',
    sortProp: 'email',
  },
  {
    _t_id: 'b65e064d',
    prop: 'active',
    text: 'Status',
    computedText: (item) => (item ? 'Active' : 'Inactive'),
    sort: true,
    filter: true,
    type: 'text',
    customFilterTabDisplay: (item) => (item ? 'Active Only' : 'Inactive Only'),
    filterType: 'eq',
    predefined: [
      {
        text: 'View Active Only',
        controlType: 'default-repeat',
        refreshOnSelect: true,
        withValue: true,
        checkbox: true,
        value: 1,
        customEval: (activeFilter) => {
          activeFilter.value = !activeFilter.value
        },
      },
    ],
  },
  {
    _t_id: 'cf8b3d04',
    prop: 'companyType/label',
    text: 'Company Type',
    sort: true,
    filter: true,
    type: 'text',
    filterType: 'contains',
    sortProp: 'companyType',
  },
  {
    _t_id: '9feed671',
    prop: 'partnerTypeLabel',
    text: 'Partner Type',
    sort: true,
    filter: true,
    predefined: [
      {
        text: 'Partner Types',
        controlType: 'default-repeat',
        defaultOnSelection: true,
        refreshOnSelect: true,
        controls: [
          {
            id: '2606d3d2',
            text: 'Partner Types',
            filterType: 'contains',
            component: PartnerTypeFilterVue,
          },
        ],
      },
    ],
    type: 'text',
    filterType: 'contains',
    sortProp: 'partnerTypeLabel',
    specificWidth: 12,
  },
  {
    _t_id: '1cez2d54',
    prop: 'partnerTypeId',
    filterType: 'eq',
    filterPlaceholder: true,
  },
  {
    _t_id: '2ab5b4b6',
    prop: 'phone',
    text: 'Phone Number',
    sort: true,
    filter: true,
    type: 'text',
    filterType: 'contains',
    sortProp: 'phone',
  },
  {
    _t_id: '80b12262',
    prop: 'dotNumber',
    text: 'DOT Number',
    sort: true,
    filter: true,
    type: 'text',
    filterType: 'contains',
    sortProp: 'dotNumber',
  },
  {
    _t_id: '1c5133e5',
    prop: 'cupopsEnabled',
    text: 'CharterUP Enabled',
    computedText: (item) => (item ? 'Yes' : 'No'),
    sort: false,
    filter: true,
    detail: false,
    customFilterTabDisplay: (item) => (item ? 'Yes' : 'No'),
    filterType: 'eq',
    predefined: [
      {
        text: 'Yes',
        controlType: 'default-repeat',
        refreshOnSelect: true,
        withValue: true,
        value: 1,
      },
      {
        text: 'No',
        controlType: 'default-repeat',
        refreshOnSelect: true,
        withValue: true,
        value: 0,
      },
    ],
  },
  {
    _t_id: 'cf00e295',
    prop: 'onboardingStatusTypeLabel',
    text: 'Onboarding Status',
    sort: true,
    filter: true,
    predefined: [
      {
        text: 'Onboarding Statuses',
        controlType: 'default-repeat',
        defaultOnSelection: true,
        refreshOnSelect: true,
        controls: [
          {
            id: '6e1cf967',
            text: 'Onboarding Statuses',
            filterType: 'contains',
            component: OnboardingStatusTypeFilterVue,
          },
        ],
      },
    ],
    type: 'text',
    filterType: 'contains',
    sortProp: 'onboardingStatusTypeLabel',
    specificWidth: 12,
  },
  {
    _t_id: '5422ad86',
    prop: 'onboardingStatusTypeId',
    filterType: 'eq',
    filterPlaceholder: true,
  },
]

const companyStatusMap = [
  { text: 'Active', value: 'true' },
  { text: 'Inactive', value: 'false' },
]
const companyTypeMap = [
  { text: 'Free', value: 'free' },
  { text: 'Paid', value: 'paid' },
]

const categories = [
  {
    _t_id: '1711fzda30',
    text: 'Company Status',
    prop: 'active',
    values: deepClone(companyStatusMap),
    type: 'text',
    method: 'or',
  },
  {
    _t_id: '1711fz30',
    text: 'Company Type',
    prop: 'companyType/key',
    values: deepClone(companyTypeMap),
    type: 'text',
    method: 'or',
  },
]

export default {
  components: {
    BaseTableWrapper,
  },
  data() {
    return {
      isAdmin: false,
      tableInit: {
        detail: CompanyViewDetail,
        detailKeyId: 'companyId',
        tableId: 'companies_tv_view',
        addNewEnabled: true,
        enableColumnConfig: true,
        areStatusFiltersShowing: true,
        categories,
        addNewHandler: () => this.$router.push({ name: 'companies.add' }),
        currentPage: 1,
        perPage: 10,
        isDetailed: true,
        columns,
        collection: 'Companies',
        action: (params) => companies.getCompanies(params),
      },
    }
  },
}
</script>
<style scoped>
::v-deep .status-filters .status-filter-select {
  max-width: 350px !important;
}

::v-deep .layout.status-filters.row.justify-space-between {
  justify-content: end;
}
</style>
