<template>
  <v-container>
    <v-layout row wrap>
      <v-flex>
        <v-layout row wrap>
          <template v-if="row.action === 'delete_company_detail'">
            <BaseDeletion
              id="company-view-detail"
              :close="closeCard"
              :collection-name="'company'"
              :row="row"
              :action="action"
            />
          </template>
          <template v-if="row.action === 'detail'">
            <CompanyQuickView :row="row" />
          </template>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import BaseDeletion from '@/components/BaseDeletion.vue'
import CompanyQuickView from '@/components/CompanyQuickView.vue'
import companies from '@/services/companies'

export default {
  components: {
    BaseDeletion,
    CompanyQuickView,
  },
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
    props: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      expanded: this.props.expanded,
      action: (params) => companies.deleteCompany(params),
    }
  },
  methods: {
    closeCard() {
      this.props.expanded = false
    },
  },
}
</script>
