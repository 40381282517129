<template>
  <v-card-title primary-title>
    <div class="cr-base-form-display-info">
      <div class="headline">
        {{ row.name }}
      </div>
      <div>
        {{ `${row.address.street1} ${row.address.street2}` }}
      </div>
      <div>
        {{ `${row.address.city}, ${row.address.state}` }}
      </div>
    </div>
  </v-card-title>
</template>

<script>
export default {
  props: {
    row: {
      type: Object,
      default: () => ({}),
      required: true,
      name: {
        type: String,
        default: () => '',
      },
      address: {
        type: Object,
        default: {},
        required: true,
        street1: {
          type: String,
          default: () => '',
        },
        street2: {
          type: String,
          default: () => '',
        },
        city: {
          type: String,
          default: () => '',
        },
        state: {
          type: String,
          default: () => '',
        },
      },
    },
  },
  mounted() {},
}
</script>
